import React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import { Container, Box, Typography } from '@mui/material';

export default function App() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/">Home</Link>
      </Box>
    </Container>
  );
}
